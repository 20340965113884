import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
`;

export const Label = styled.div`
  text-transform: uppercase;
  width: 150px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  background: #eaecef;
  border: 1px solid rgb(204, 204, 204);
  border-right: none;
  border-left: none;
`;
export const MinusButton = styled.div`
  background: #001c72;
  width: 40px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  line-height: 38px;
  font-weight: bold;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
`;
export const PlusButton = styled.div`
  background: #001c72;
  width: 40px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  cursor: pointer;
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
`;

export const Input = styled.input`
  border-radius: 0;
  width: calc(100% - 230px);
  font-size: 18px;
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
